import React from 'react'
import { useMutation } from '@apollo/client'
import { Button, Tooltip, useColorMode } from '@chakra-ui/react'
import { gql } from 'graphql-tag'
// import { NavDataContext } from '../../../contexts/NavDataContext'
import Icon from '../Design/Icon'
import useColor from '../../../hooks/useColor'
// import UserContext from '@contexts/UserContext'
import { useUserContext } from '@contexts/UserContext'

const ADD_COLOR_SETTING = gql`
  mutation ADD_COLOR_SETTING($name: String!, $username: String!, $value: String!) {
    addUserSetting(input: [{ name: $name, value: $value, user: { username: $username } }]) {
      numUids
    }
  }
`
const UPDATE_COLOR_SETTING = gql`
  mutation UPDATE_COLOR_SETTING($id: ID!, $value: String!) {
    updateUserSetting(input: { filter: { id: [$id] }, set: { value: $value } }) {
      numUids
    }
  }
`

const SaveColorMode = (props) => {
  const { type } = props
  const bg = useColor('colorModeToggleButtonBG')
  // const bg = useColorModeValue('danger','gray1')
  // const navDataContext = React.useContext(NavDataContext)
  const {
    username,
    token,
    setToken,
    userToken,
    adminToken,
    expires,
    canBeAdmin,
    setLogin,
    logout,
    settings,
    data: { isContact: { name }, hasEmail: { email } } = { isContact: { name: undefined }, hasEmail: { email: undefined } },
    // settings: { profilePic: { value: pic } = { value: undefined } } = { profilePic: { value: undefined } },
  } = useUserContext()
  //   const contactID = navDataContext?.user?.data?.isContact?.id
  // const username = navDataContext?.user?.username
  // const token = navDataContext?.user?.token
  // const settings = navDataContext?.user?.settings || {}
  const { colorMode, toggleColorMode } = useColorMode(settings?.colorMode?.value)
  const [saveMutation] = useMutation(
    gql`
      mutation COLORMODE_PLACEHOLDER {
        placeholder
      }
    `
  )
  const saveSetting = (name, value, id = null) => {
    if (id) {
      // update setting
      saveMutation({
        mutation: UPDATE_COLOR_SETTING,
        variables: {
          id,
          value,
        },
        refetchQueries: ['getUser'],
        context: { headers: { auth: token } },
        // this ignoreResults simplydoes not update the data property in the useMutation hook
        ignoreResults: true,
      })
    } else {
      // add setting
      if (!username) {
        console.error('Cannot add user setting without a logged in user.')
        return
      }
      saveMutation({
        mutation: ADD_COLOR_SETTING,
        variables: {
          username,
          name,
          value,
        },
        refetchQueries: ['getUser'],
        context: { headers: { auth: token } },
        ignoreResults: true,
      })
    }
    // update.current = true
  }
  // if (type === 'icon') {
  // colorMode === 'light' ? _hover={{ bg: "#ebedf0" }} : 'light'
  return (
    // <Tooltip hasArrow label='Toggle Dark Mode'>
      <Button
        mx={1.5}
        bg={bg}
        size='sm'
        onClick={() => {
          const color = colorMode === 'light' ? 'dark' : 'light'
          toggleColorMode()
          saveSetting('colorMode', color, settings?.colorMode?.id)
        }}
      >
        {type === 'icon' ? (
          <Icon color={colorMode === 'light' ? 'mb_atomic[700]' : 'white'} fill name={colorMode === 'light' ? 'moon' : 'sun'} />
        ) : colorMode === 'light' ? (
          'Toggle Dark'
        ) : (
          'Toggle Light'
        )}
      </Button>
    // </Tooltip>
  )
}
export default SaveColorMode
