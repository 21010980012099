import { Flex } from '@chakra-ui/react'
import Head from 'next/head'
// import Link from 'next/link'
import Footer from './Footer'
// import Header from './Header'

export default function Main({ children, pageTitle, ...props }) {
  return (
    <Flex flexDirection='column' height='100vh' overflowY='auto' overflowX='hidden'>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>{pageTitle}</title>
      </Head>

      <Flex flexDirection='column' flexGrow='1' {...props}>
        {children}
      </Flex>

      <Footer />
    </Flex>
  )
}
