import { useColorMode } from '@chakra-ui/react'
import { colorModes } from '../theme/foundations/colors'

const useColor = (colorName: string, forcedMode?: 'light' | 'dark'): string | null => {
  const { colorMode } = useColorMode()
  const cm = forcedMode ?? colorMode
  if (colorName in colorModes) {
    if (Array.isArray(colorModes[colorName]) && colorModes[colorName].length === 2) {
      return colorModes[colorName][cm === 'dark' ? 1 : 0]
    }
    return colorModes[colorName]
  }
  return null
}

export default useColor
